// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("robot-medical-care.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ComingSoon.css */

.coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
    color: #ffffff; /* Change font color for readability */
    text-align: center;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center fixed;
    background-size: cover; /* Ensure the background covers the entire page */
}

.overlay {
    background-color: rgba(49, 120, 141, 0.5); /* Dark overlay for better text visibility */
    padding: 42px;
    border-radius: 22px;
}

h1 {
    font-size: 4.5em;
    margin-bottom: 0.5em;
}

p {
    font-size: 1.2em;
    margin-bottom: 1.5em;
}

.email-signup {
    margin-top: 20px;
}

input[type="email"] {
    padding: 10px;
    font-size: 1em;
    width: 80%;
    max-width: 300px;
    margin: 10px;
}

input[type="submit"] {
    padding: 10px 15px;
    font-size: 1em;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
}

input[type="submit"]:hover {
    background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/ComingSoon.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,8BAA8B;IAC9B,cAAc,EAAE,sCAAsC;IACtD,kBAAkB;IAClB,iFAAwE;IACxE,sBAAsB,EAAE,iDAAiD;AAC7E;;AAEA;IACI,yCAAyC,EAAE,4CAA4C;IACvF,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,UAAU;IACV,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* ComingSoon.css */\n\n.coming-soon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    margin: 0;\n    font-family: Arial, sans-serif;\n    color: #ffffff; /* Change font color for readability */\n    text-align: center;\n    background: url('robot-medical-care.jpeg') no-repeat center center fixed;\n    background-size: cover; /* Ensure the background covers the entire page */\n}\n\n.overlay {\n    background-color: rgba(49, 120, 141, 0.5); /* Dark overlay for better text visibility */\n    padding: 42px;\n    border-radius: 22px;\n}\n\nh1 {\n    font-size: 4.5em;\n    margin-bottom: 0.5em;\n}\n\np {\n    font-size: 1.2em;\n    margin-bottom: 1.5em;\n}\n\n.email-signup {\n    margin-top: 20px;\n}\n\ninput[type=\"email\"] {\n    padding: 10px;\n    font-size: 1em;\n    width: 80%;\n    max-width: 300px;\n    margin: 10px;\n}\n\ninput[type=\"submit\"] {\n    padding: 10px 15px;\n    font-size: 1em;\n    cursor: pointer;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n}\n\ninput[type=\"submit\"]:hover {\n    background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
