// ComingSoon.js
import React from 'react';
import './ComingSoon.css'; // Importing CSS styles


const ComingSoon = () => {
    return (
        <div className="coming-soon">
            <div className="overlay">
                <h1>Tia2 Vem Aí !!!</h1>
                <p>Sua Assistente Profissional.</p>
               
            </div>
        </div>
    );
};

export default ComingSoon;