// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import ComingSoon from './ComingSoon'; // Import the ComingSoon component


function App() {
  return (
    <div className="App">
            <ComingSoon />
        </div>
  );
}

export default App;
